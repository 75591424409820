<template>
    <modal
      :modalId="`modalGoalUser`"
      :load="false"
      :typeLg="`modal_md`"
    >
      <div class="col-md-12 mb-1 label">
        <label class="label_default label_blue label_lg">
            <strong>Atualizar meta</strong> 
        </label>
      </div>
      <div class="row align-middle">
        <ValidationProvider
          tag="div"
          v-slot="{ errors, ariaMsg, classes }"
          :rules="{
            required: true
          }"
          class="col-md-5 mb-1 form-group form-outline"
          name="Meta da Unidade"
          :mode="custom"
        >
          <label class="mb-1 label_default" :class="classes"
            >Meta do usuário</label
          >
          <money
            v-if="salesman != null && salesman.dashboardUser != null"
            v-model="salesman.dashboardUser.userGoal"
            v-bind="money"
            class="input_default form-control"
            :class="classes"
            maxlength="12"
          />
          <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <br>
      <div class="row m-auto col-md-12">
        <div class="col-md-12 ml-auto">
          <div class="row">
            <div class="col-md-5 m-auto">
              <button
                type="button"
                class="btn btn_secondary"
                @click.prevent="$emit('closeModalGoalUser')"
              >
                Fechar
              </button>
            </div>
            <div class="col-md-5 m-auto">
              <button
                v-if="salesman != null && salesman.dashboardUser != null"
                type="button"
                @click.prevent="updateUserGoal(salesman.dashboardUser.userGoal)"
                class="btn btn_default"
              >
              Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </template>
  
  <script>
  import { Money } from "v-money";
  import { mapGetters } from "vuex";
  export default {
    components: {
      modal: () => import("@/components/modal/modalDefault.vue"),
      Money
    },
    props: ['salesman'],
    data() {
      return {
        money: {
          decimal: ",",
          thousands: ".",
          prefix: "R$ ",
          precision: 2,
          masked: false
        },
        user: []
      };
    },
    methods: {
      updateUserGoal(newUserGoal) {
        this.$emit('sendUser', newUserGoal)
      },
      custom() {
        return { on: ["blur"], debounce: 1000 };
      }
    },
    computed: {
      ...mapGetters(["get_tenant", "getUser"]),
		}
  };
  </script>
  
  <style scoped src="@/assets/style/modal.css"></style>
  <style scoped>
  .b_danger {
    color: red;
  }
  .label {
    text-align: center;
  }
  .icon {
    padding-top: 20px;
  }
  #tooltip {
  display: flex;
  float: right !important;
  width: 20px !important;
}
.fas {
  font-size: 20px;
  transition: all 0.2s linear;
  cursor: pointer;
}
  </style>
  