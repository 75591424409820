<template>
  <div class="container pt-5">
    <div class="d-flex justify-content-between align-items-center">
      <router-link to="/" tag="div">
        <h3 class="text-right allSubText">
          <img
            class="back"
            src="@/assets/image/metas/arrow.svg"
            alt="arrow.svg"
          />
          Voltar
        </h3>
      </router-link>
      
      <ButtonToggle @toggleConvert="toggleBtn" :activo="activoCurrent" />
    </div>
    <div
      v-if="load || get_masterTenantCards.load"
      class="d-flex align-items-center justify-content-center w-100"
    >
      <half-circle-spinner
        :animation-duration="1000"
        :size="100"
        color="#fff"
      />
    </div>
    <div v-else>

      <div
        class="col-auto d-flex justify-content-center flex-wrap m-auto"
        v-if="profile !== 1"
      >
        <metricaboxFranqueado
          v-for="(item, index) in pessoas"
          :key="index + 2"
          :item="item"
          class="itens list-complete-item m-2"
          :porceto="activoCurrent"
          @openModalGoalUser="openModalGoalUser"
        />
      </div>
      <div
        class="col-auto d-flex justify-content-center flex-wrap m-auto"
        v-else
      >
        <boxFranqueado
          class="itens list-complete-item"
          v-for="(item, index) in get_tenantInformations"
          :key="index + 1"
          :item="item"
          :porceto="activoCurrent"
          @edit="showModalEdit"
          @enterTenant="enterTenant"
        />
      </div>
    </div>
    <component
      :is="cp"
      :listUser="listUser"
      :id.sync="idModal"
      :tenantDisableds="get_tenantDisableds"
      :tenantsActives="get_tenantInformations"
      @closeModal="closeModal"
      @getUnidades="getAll"
    />
    <modalGoalUser v-if="salesman != null" :salesman="salesman" @sendUser="sendUser" @closeModalGoalUser="closeModalGoalUser"/>
  </div>
</template>

<script>
import HTTP from "@/api_system";
import metricaboxFranqueado from "@/components/metas/Franqueado/_Metas_mensais_funcionario.vue";
import boxFranqueado from "@/components/dashboard_master/components/box.vue";
import ButtonToggle from "@/components/metas/buttons/button_toggle.vue";
import { HalfCircleSpinner } from "epic-spinners";
import ModalEdit from "@/components/adm/unidades/modal/modalUnidade.vue";
import modalGoalUser from '@/components/metas/Franqueado/modal/ModalGoalUser.vue';
import { mapGetters } from "vuex";

export default {
  components: {
    metricaboxFranqueado,
    ButtonToggle,
    boxFranqueado,
    HalfCircleSpinner,
    ModalEdit,
    modalGoalUser
  },
  data() {
    return {
      salesman: {},
      userGoal: 0,
      pessoas: [],
      activoCurrent: "Percent",
      load: false,
      idModal: "",
      listUser: "",
      cp: ""
    };
  },
  computed: {
    profile() {
      return this.$store.getters.getUser.profile;
    },
    get_tenantInformations() {
      if (
        this.get_masterTenantCards.result &&
        this.get_masterTenantCards.result.tenantInformations
      ) {
        return this.get_masterTenantCards.result.tenantInformations;
      }
      return [];
    },
    get_tenantDisableds() {
      if (
        this.get_masterTenantCards.result &&
        this.get_masterTenantCards.result.tenantsDisableds
      ) {
        return this.get_masterTenantCards.result.tenantsDisableds;
      }
      return [];
    },
    ...mapGetters(["get_masterTenantCards", "getUser"])
  },
  mounted() {
    this.allPerson();
    this.profile === 1 ? this.getMaster() : this.getAll();
  },
  methods: {
    async getAll() {
      try {
        this.load = true;
        const result = await this.$store.dispatch("GetScoreOfSalesman");
        this.pessoas = result;
        this.load = false;
      } catch (error) {
        console.log(error);
        this.load = false;
      }
    },
    async getMaster() {
      try {
        await this.$store.dispatch("GetDashboardMaster");
      } catch (error) {
        this.toast("Ops algo deu errado", this.$toast.error);
      }
    },
    allPerson() {
      this.$store.dispatch("getPessoas").then(resolve => {
        this.$nextTick(() => {
          let array = [];
          resolve.forEach(item => {
            if (item.profile == 2) {
              array.push({ userId: item.id, displayName: item.displayName });
            }
          });
          this.listUser = array;
        });
      });
    },
    async toggleBtn(changeValue) {
      if (this.profile === 1) {
        let param = changeValue == "Percent" ? undefined : true;
        await this.$store.dispatch("GetDashboardMaster", param);
      }
      this.activoCurrent = changeValue;
    },
    showModalEdit(params) {
      this.idModal = params.id;
      this.cp = "ModalEdit";
      this.$nextTick(() => {
        $("#modalUnidade").modal("show");
      });
    },
    closeModal() {
      $(`#modalUnidade`).modal("hide");
      this.cp = "";
    },
    enterTenant(params) {
      this.load = true;
      HTTP.post(
        `TokenAuth/AuthenticateFromMaster?tenantId=${params.id}&userId=${this.getUser.id}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      )
        .then(response => {
          this.$store.commit(
            "set_originalToken",
            this.$store.getters.getterToken
          );
          this.$store.commit(
            "set_loginClone",
            this.$store.state.login.userLogin
          );
          this.$store.commit(
            "set_tenantClone",
            this.$store.state.tenent.tenant
          );
          this.$nextTick(() => {
            this.$store.commit("set_token", response.data.result.accessToken);
            this.$store.commit("set_user", response.data.result.informations);
            this.$store.commit("set_tenant", response.data.result.tenant);

            this.$router.push("/");
            this.$store.dispatch("getAlert");
            this.$store.dispatch("getAlertImportant");
            this.toast(`Unidade ${params.name} Selecionada!`, this.$toast);
          });
          this.load = false;
        })
        .catch(error => {
          this.load = false;
          this.toast("Ops algo deu errado", this.$toast.error);
        });
    },
    openModalGoalUser(item) {
      this.salesman = item;
      //this.$emit('update:item', item);
      $('#modalGoalUser').modal('show');
    },
    closeModalGoalUser() {
      $('#modalGoalUser').modal('hide');
      this.getAll();
    },
    sendUser(meta) {
      
      if (meta > this.$store.getters.tenantGoal) {
        this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i>A meta do vendedor não pode ser maior que a meta da unidade.`,
          });
      } else {
        this.load = true;
        var objPost = {
          id: this.salesman.dashboardUser.userId,
          displayName: this.salesman.dashboardUser.userDisplayName,
          name: this.salesman.dashboardUser.userName,
          email: this.salesman.dashboardUser.userEmail,
          photo: this.salesman.dashboardUser.userPhoto,
          cpf: this.salesman.dashboardUser.userCpf.toString(),
          profile: this.salesman.dashboardUser.userProfile,
          cellPhone: this.salesman.dashboardUser.userCellPhone,
          genre: parseInt(this.salesman.dashboardUser.userGenre),
          goal: meta
        };
        this.$store.dispatch("editCurrentUser", objPost).then((resolve) => {
          if (resolve.success) {
            this.$notify({
              group: "erros",
              type: "sucess",
              text: `<i class="icon ion-close-circled"></i>${resolve.msg}`,
            });
            this.reloadCP();
          } else {
            this.$notify({
              group: "erros",
              type: "error",
              text: `<i class="icon ion-close-circled"></i>${resolve.msg}`,
            });
          }
        });
        this.closeModalGoalUser();
        this.load = true;
      }
    }
  },
  "get_masterTenantCards.error"() {
    if (this.get_masterTenantCards.error) {
      this.toast("Ops, erro ao carregar as unidades", this.$toast.error);
    }
  }
};
</script>

<style>
.allSubText {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #98c4e6;
  cursor: pointer;
}
.allSubText .back {
  transform: rotate(180deg);
}
</style>
